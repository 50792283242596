/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useReducer, useState, useCallback } from 'react';
import { each, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// @material-ui/core
import Checkbox from '@material-ui/core/Checkbox';
import Modal from '@material-ui/core/Modal';
import withStyles from '@material-ui/core/styles/withStyles';
// components
import Button from 'components/Button/Button';
import ToggleComponent from 'components/Toggle/Toggle';
import Typography from 'components/Typography/Typography';
import { VSpacer } from 'components/Spacer/Spacer';
// views
import DebugComponent from './DebugComponent';
import ImageControls from './ImageControls/ImageControls';
import Photos from './Photos';
import SKUTable from './SKUTable';
import ShelfVisualizer from '@infilect_developer/shelf-visualiser';

const styles = theme => ({
  modalRoot: {
    overflow: 'scroll'
  },
  modalContent: {
    color: theme.palette.type === 'light' ? ' #767676 ' : '#94949a',
    backgroundColor: theme.palette.type === 'light' ? '#fff' : '#1e1e2b',
    margin: 100,
    padding: 100,
    outline: 'none'
  },
  checkbox: {
    color: theme.palette.type === 'light' && '#0f0d36 !important'
  },
  button: {
    background: theme.palette.type === 'light' ? '#18c7fe' : 'rgb(39, 42, 61)',
    color: theme.palette.type === 'light' && 'white',

    '&:hover': {
      background:
        theme.palette.type === 'light' ? '#18c7fe' : 'hsla(0, 1%, 28%, 0.2)',
      color: theme.palette.type === 'light' && 'white'
    },
    '&:focus': {
      background: theme.palette.type === 'light' ? '#18c7fe' : '#ffffff33',
      color: theme.palette.type === 'light' && 'white'
    }
  },
  flex: {
    display: 'flex',
    alignItems: 'baseline'
  },
  infoCheckbox: {
    marginLeft: 'auto',
    alignItems: 'center',
    '& h4': {
      color: 'rgba(0,0,0,0.87)'
    }
  },
  modeToggle: {
    '& button': {
      marginLeft: 10
    }
  },
  signal: {
    width: 30,
    height: 30
  },
  problem: {
    marginRight: 10
  },
  checkBoxGroup: {
    alignItems: theme.palette.type === 'light' && 'center',
    '& h4': {
      color: theme.palette.type === 'light' && '#767676'
    }
  },
  sessionIdWrapper: {
    [theme.breakpoints.up('xl')]: {
      maxWidth: 'none'
    },
    maxWidth: '12vw',
    paddingLeft: '10px'
  },
  sessionIdText: {
    marginLeft: 'auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

const initialState = {
  activeBoxes: [],
  correctionData: [],
  activeMode: 'include',
  tableData: {},
  activeControls: {}
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_ACTIVE_BOXES':
      return { ...state, activeBoxes: action.payload };
    case 'UPDATE_ACTIVE_CONTROLS':
      return { ...state, activeControls: action.payload };
    case 'CHANGE_MODE':
      return { ...state, activeMode: action.payload };
    case 'UPDATE_TABLE_DATA':
      return { ...state, tableData: action.payload };
    case 'UPDATE_MULTIPLE':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

let selectedRows = {};

const controls = ['zoomIn', 'reset'];

const checkboxes = [{ name: 'showStickers', title: 'Show Stickers' }];

const StyledCheckbox = styled(Checkbox)`
  && {
    color: ${props => `${props.checkboxColor} !important`};
  }
`;

// eslint-disable-next-line react/prefer-stateless-function
const TestStoreExplorerPage = ({
  photosList,
  tableData,
  productFilter,
  saveDebug,
  handleDataDownload,
  updateProductFacing,
  variantList,
  dashboardType,
  tseWorkflow,
  shelfData,
  saveFacings,
  sessionInfo,
  sessionName,
  sessionDate,
  fixProductFacingAPIStatus,
  updateProductFacingAPIStatus,
  dispatchUpdateProductFacing,
  handleTseCorrectionCompleted
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isModalOpen, setModalState] = useState(false);
  const [toggleState, setToggleState] = useState(false);
  const [checkBoxState, setCheckBoxState] = useState({});
  const [isCropInfoHandlerSelected, setCropInfoHandlerSelected] = useState(
    false
  );

  const updateFacing = useCallback(data => {
    const newBoxes = data.filter(item => !item.isSaved);
    const formattedParams = newBoxes.map(box => {
      let obj = {};
      obj.box_id = box.id;
      obj.id = box.variantId;
      if (tseWorkflow === 'v2') {
        obj.photo_id = box.photoID;
      }
      obj.include = box.inclusion;
      obj.is_exclusion = box.is_exclusion;
      obj.is_overlap = box.is_overlap;
      return obj;
    });

    const paramsObj = { 
      "session_id": shelfData?.session_data?.[0],
      "photo_id": [],
      "updated_data": {
        "facing_ids": [],
        "include": true,
        "is_exclusion": false,
        "is_overlap": false,
      }
    }

    if (tseWorkflow === 'v2' && formattedParams.length > 0) {
      const tempParam = formattedParams[0]
      paramsObj.updated_data.include = tempParam.include
      paramsObj.updated_data.is_exclusion = tempParam.is_exclusion
      paramsObj.updated_data.is_overlap = tempParam.is_overlap

      formattedParams.forEach(res => {
        if (!paramsObj.photo_id.includes(res.photo_id)) {
          paramsObj.photo_id.push(res.photo_id)
        }
        if (!paramsObj.updated_data.facing_ids.includes(res.box_id)) {
          paramsObj.updated_data.facing_ids.push(res.box_id)
        }
      })
    }
    if (newBoxes.length) {
      dispatchUpdateProductFacing({
        params: tseWorkflow === 'v2' ? paramsObj:formattedParams,
        tseWorkflow
      });
    }
  });

  const markTseCorrectionsCompleted = useCallback(data => {
    handleTseCorrectionCompleted()
  })

  const toolbarConfig = [
    {
      name: 'viewInfo',
      type: 'checkbox',
      label: ['View Info'],
      data: 'options goes here',
      enabled: true
    },
    {
      name: 'inclusion',
      type: 'toggle',
      label: ['Include', 'Exclude'],
      enabled: true
    },
    {
      name: 'save',
      type: 'button',
      label: ['Save'],
      action: val => updateFacing(val),
      enabled: true
    },
		...(tseWorkflow === 'v2'? [{
		  name: 'complete',
		  type: 'button',
		  label: ['Submit'],
		  onClick: () => markTseCorrectionsCompleted(),
		  enabled: true
		}]: [])
  ];

  useEffect(() => {
    if (!saveDebug.isLoading && !saveDebug.error) {
      setModalState(false);
    }
  }, [saveDebug]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_TABLE_DATA',
      payload: { ...tableData }
    });
  }, [tableData]);

  const handleModeToggle = () => {
    dispatch({
      type: 'CHANGE_MODE',
      payload: state.activeMode === 'exclude' ? 'include' : 'exclude'
    });
  };

  const handleCheckBoxClick = ({
    row,
    columns,
    isChecked,
    i: index,
    selectAll = false
  }) => {
    if (isChecked) {
      if (selectAll) {
        each(row, (r, i) => {
          selectedRows[`${i}t`] = r.bnBox.map(re => ({
            ...re,
            rowID: i,
            rowDetails: { ...r, bnBox: null },
            columns
          }));
        });
      } else {
        selectedRows[`${index}t`] = row.bnBox.map(re => ({
          ...re,
          rowDetails: { ...row, bnBox: null },
          columns,
          rowID: index
        }));
      }
    } else if (selectAll) {
      selectedRows = {};
    } else {
      delete selectedRows[`${index}t`];
    }
    let boxesCoords = [];
    each(selectedRows, r => {
      boxesCoords = [...boxesCoords, ...r];
    });
    dispatch({ type: 'UPDATE_ACTIVE_BOXES', payload: boxesCoords });
  };

  // const resetAnnotation = () => {
  //   dispatch({
  //     type: 'UPDATE_MULTIPLE',
  //     payload: {
  //       activeBoxes: [],
  //       tableData,
  //       correctionData: {}
  //     }
  //   });
  // };

  const saveAnnotation = () => {
    if (state.correctionData.length) {
      updateProductFacing({
        params: state.correctionData
      });
    }
    dispatch({
      type: 'UPDATE_MULTIPLE',
      payload: {
        correctionData: []
      }
    });
  };

  const handleCropClick = ({ crop }) => {
    const cropVariant = variantList.find(
      variant => variant.id === crop.rowDetails.variantId
    );
    const originalBox = cropVariant.bounding_boxes.find(
      box => box.id === crop.id
    );
    const clickedBoxes = state.correctionData.map(item => item.box_id);

    let correctionData = [...state.correctionData];

    const indexOfCorrectionData = correctionData
      .map(item => item.box_id)
      .indexOf(crop.id);

    const boxes = state.activeBoxes.map(box => {
      if (box.id === crop.id) {
        return {
          ...box,
          color:
            clickedBoxes.includes(crop.id) &&
            (correctionData[indexOfCorrectionData] || {}).include ===
              (state.activeMode !== 'exclude')
              ? originalBox.color
              : state.activeMode === 'exclude'
              ? '#FF0000'
              : '#44ec09'
        };
      }
      return box;
    });
    const datasets = [...new Set(state.tableData.datasets)];
    datasets[crop.rowID].bnBox = boxes;
    const updatedTableData = {
      ...state.tableData,
      datasets
    };

    if (indexOfCorrectionData < 0) {
      correctionData.push({
        box_id: crop.id,
        id: crop.rowDetails.variantId,
        include: state.activeMode !== 'exclude'
      });
    } else if (
      correctionData[indexOfCorrectionData].include !==
      (state.activeMode !== 'exclude')
    ) {
      correctionData.splice(indexOfCorrectionData, 1);
      correctionData.push({
        box_id: crop.id,
        id: crop.rowDetails.variantId,
        include: state.activeMode !== 'exclude'
      });
    } else {
      correctionData.splice(indexOfCorrectionData, 1);
    }
    dispatch({
      type: 'UPDATE_MULTIPLE',
      payload: {
        activeBoxes: boxes,
        tableData: updatedTableData,
        correctionData
      }
    });
  };

  const handleImageControls = ({ e, isActive }) => {
    switch (e) {
      case 'zoomIn':
        return dispatch({
          type: 'UPDATE_ACTIVE_CONTROLS',
          payload: { ...state.activeControls, [e]: !isActive }
        });
      case 'reset':
        return dispatch({
          type: 'UPDATE_ACTIVE_CONTROLS',
          payload: { ...state.activeControls, [e]: true }
        });
      default:
        return null;
    }
  };

  const handleCheckBoxChange = checkBox => {
    setCheckBoxState(p => ({
      ...p,
      [checkBox.name]: !p[checkBox.name]
    }));
  };

  return Object.keys(photosList).length > 0 &&
    Object.keys(state.tableData).length > 0 ? (
    <>
      {
        (tseWorkflow === 'v2' && shelfData?.session_data?.length > 1) && (
          <div style={{
            width: '100%',
            padding: '10px 20px',
            background: '#2196F3',
            color: 'white',
            margin: '20px 0px'
          }}>
            <h2 style={{
              marginBottom: '10px',
              color: 'white'
            }}>Alert!</h2>
            <p>More than one session id's received please add session id filter on left</p>
          </div>
        )  
      }
      <ShelfVisualizer
        sessionData={shelfData}
        fontFamily={
          dashboardType === 'v2'
            ? 'loginPageFont'
            : `'Helvetica', 'Arial', sans-serif`
        }
        theme={dashboardType === 'v2' ? 'light' : 'dark'}
        tseWorkflow={tseWorkflow}
        seamApiKey={process.env.REACT_APP_SEAM_API_KEY}
        handleDataDownload={handleDataDownload}
        isTse={tseWorkflow === 'v2' && shelfData?.session_data?.length !== 1? false:true}
        toolbarConfig={toolbarConfig}
        // subFeatureConfig={}
        fixFacingFilters={productFilter.data}
        saveFacings={saveFacings}
        sessionName={sessionName || ''}
        sessionDate={sessionDate || ''}
        shelfConfig={[{ key: 'sku_table_header', enable: false }]}
        isDisplay={sessionInfo.isDisplay}
        fixProductFacingAPIStatus={fixProductFacingAPIStatus}
        updateProductFacingAPIStatus={updateProductFacingAPIStatus}
        headerKeys={['count', "full_name", 'category' ]}
        headerImageKey={'packshot'}
      />
    </>
  ) : null;
};

TestStoreExplorerPage.propTypes = {
  useExport: PropTypes.bool,
  useDisplayTag: PropTypes.bool,
  sessionId: PropTypes.string,
  stickerBoxes: PropTypes.array,
  photosList: PropTypes.array.isRequired,
  tableData: PropTypes.object.isRequired,
  productFilter: PropTypes.object.isRequired,
  fixProductFacingState: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  debugProblems: PropTypes.array.isRequired,
  saveDebug: PropTypes.object.isRequired,
  handleDataDownload: PropTypes.func,
  updateProductFacing: PropTypes.func.isRequired,
  fixProductFacing: PropTypes.func.isRequired,
  handleDebugSave: PropTypes.func,
  primaryButtonStyles: PropTypes.object,
  cardStyles: PropTypes.object,
  dashboardPanelStyles: PropTypes.object,
  dashboardType: PropTypes.string,
  tooltipStyles: PropTypes.object,
  dropdownStyles: PropTypes.object,
  dropdownTitleStyles: PropTypes.object
};

TestStoreExplorerPage.defaultProps = {
  useExport: false,
  useDisplayTag: false,
  stickerBoxes: [],
  sessionId: '',
  handleDataDownload: () => {},
  handleDebugSave: () => {},
  primaryButtonStyles: {},
  cardStyles: {},
  dashboardPanelStyles: {},
  dashboardType: '',
  tooltipStyles: {},
  dropdownStyles: {},
  dropdownTitleStyles: {}
};

export default withStyles(styles)(TestStoreExplorerPage);
